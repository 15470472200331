import { darkColor } from "@/constants/colors";
import { useRouter } from "next/router";
import useIsScrolledDown from "./useIsScrolledDown";

const useNavbarFontColor = () => {
  const isScrolledDown = useIsScrolledDown(100);
  const { pathname } = useRouter();
  if (isScrolledDown) {
    return darkColor;
  } else if (
    pathname.startsWith("/sports") ||
    pathname.startsWith("/features/youth-sports-background-checks") ||
    pathname.startsWith("/features/sports-registration-software") ||
    pathname.startsWith("/features/sports-scheduling-software") ||
    pathname.startsWith("/features/sports-team-communication-app") ||
    pathname.startsWith("/sign-up") ||
    pathname.startsWith("/terms") ||
    pathname.startsWith("/privacy") ||
    pathname.startsWith("/cookie-policy") ||
    pathname.startsWith("/data-deletion") ||
    pathname.startsWith("/tools/sports-schedule-generator/new")
  ) {
    return darkColor;
  } else {
    return "#fff";
  }
};

export default useNavbarFontColor;
